.price-selector {
	width: 150px;
	height: 30px;
	margin-left: auto;
	margin-right: auto;

	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.price-selector>.price {
	width: 50px;
	height: 100%;
	background-color: var(--colour-card);

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	box-shadow: 5px 5px 5px var(--colour-shadow-invert);
}

.price-selector>.price.left {
	border-radius: 25px 0 0 25px;
}

.price-selector>.price.right {
	border-radius: 0 25px 25px 0;
}

.price-selector>.price:hover {
	background-color: var(--colour-card-hover);
}

.price-selector>.price.selected {
	background-color: var(--colour-card-title);
}
