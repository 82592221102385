.divider {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    overflow: hidden;
    height: 20px;
}

.divider div {
    width: 100%;
    height: 1px;
    border: none;
    background-color: var(--colour-divider);
}

.divider span {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 0 8px;
    white-space: nowrap;
    background-color: var(--colour-background);
    color: var(--colour-divider);
    font-size: 12px;
    user-select: none;
}
