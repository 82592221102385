* {
	transition:
		color 0s,
		background-color 0.1s,
		box-shadow 0.1s,
		transform 0.1s,
		min-width 0.2s,
		max-width 0.2s,
		width 0.2s,
		height 0.2s,
		max-height 0.2s,
		overflow 0.2s,
		opacity 0.2s,
		padding 0.2s,
		border 0.1s,
		!important;

	transition-delay: 0 !important;
}

/* Navbar */

.navbar {
	background-color: var(--colour-nav);
	box-shadow: 0 5px 10px var(--colour-shadow);
	min-height: 65px !important;
}

.navbar-brand {
	font-size: 20px !important;
	color: var(--colour-text) !important;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	white-space: nowrap !important;
	margin-bottom: 4px;
	display: flex !important;
	align-items: center;
}

.navbar-brand>img {
	margin-right: 5px;
	margin-top: 2px;
}

.nav-link {
	display: flex;
	font-size: 18px !important;
	color: var(--colour-text) !important;
	padding-left: 0 !important;
	align-items: center;
}

.navbar-toggler {
	border-color: var(--colour-text) !important;
}

.nav-link>img {
	width: 0px;
}

button:focus {
	outline-width: 0px !important;
}

@media (min-width: 992px) {
	/* Styles only applied when navbar is in non-collapsed form */

	.navbar {
		height: 65px !important;
	}

	.dropdown-menu {
		right: auto !important;
		left: 50% !important;
		-webkit-transform: translate(-50%, 0) !important;
		-o-transform: translate(-50%, 0) !important;
		transform: translate(-50%, 0) !important;
		text-align: center !important;
	}

	.nav-item {
		margin-left: 15px;
	}

	#my-navbar>.btn {
		border-width: 2px;
		height: 35px;
		font-size: 15px;
		font-weight: 500;
		line-height: 18px;
		overflow-x: auto;
	}

	.navbar-nav>.nav-link {
		margin-left: 15px;
	}

	.user-dropdown {
		padding: 0px;
		display: flex;
		align-items: center;
	}

	.nav-link>img {
		width: 35px;
		height: 35px;
		border-radius: 17.5px;
		margin-right: 8px;
		width: initial !important;
	}

	.dropdown-toggle::after {
		margin-left: 8px;
		margin-bottom: -1px;
	}
}

.dropdown-menu {
	background-color: var(--colour-dropdown) !important;
	overflow-y: auto;
	scrollbar-color: var(--colour-nav) var(--colour-dropdown);
	height: auto;
	max-height: min(266.5px, calc(100vh - 65px));
}

.dropdown-item {
	font-size: 15px;
	color: var(--colour-text) !important;
}

.dropdown-item:hover {
	background-color: var(--colour-dropdown-hover) !important;
}

.guild-dropdown {
	padding: 0px;
	display: flex;
	align-items: center;
}

.guild-dropdown>div {
	text-align: start !important;
}

.guild-dropdown>div>a {
	padding-top: 6px;
	padding-bottom: 6px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.guild-dropdown>div>a>img {
	width: 35px;
	height: 35px;
	border-radius: 17.5px;
	margin-right: 10px;
	width: initial !important;
}

.guild-dropdown-link {
	color: var(--colour-text-muted) !important;
}

/* Main */

body {
	font-weight: 400;
	color: var(--colour-text)
}

main {
	min-height: 100vh;
	background-color: var(--colour-background);
}

br {
	user-select: none;
}

h1 {
	font-size: 50px !important;
	text-align: center !important;
	font-weight: 100 !important;
	margin-top: 30px !important;
	color: var(--colour-text-invert) !important;
}

h2 {
	font-size: 30px !important;
	text-align: center;
	font-weight: 100 !important;
	margin-top: 5px;
	color: var(--colour-text-invert) !important;
}

.btn {
	box-shadow: none !important;
}

.commands-section {
	border-bottom-style: hidden;
	width: 100%;
	margin-bottom: 20px;
	font-size: 16px;
	margin-left: 20px;
	margin-right: 20px;
}

.commands-section>tbody>tr>td {
	padding: 8px;
}

.message-logs-table {
	border-bottom-style: hidden;
	width: 100%;
	margin-bottom: 20px;
	font-size: 16px;
}

.message-logs-table>tbody>tr>td {
	padding-bottom: 20px;
}

.message-logs-table>tbody>tr>td:nth-child(1) {
	padding-right: 20px;
}

.message-logs-table>tbody>tr>td:nth-child(2) {
	padding-left: 20px;
}

/* Scrollbars */

::-webkit-scrollbar {
	background-color: var(--colour-scrollbar);

	width: 14px;
}

::-webkit-scrollbar-thumb {
	background-color: var(--colour-scrollbar-thumb);
}

::-webkit-scrollbar-corner {
	background-color: var(--colour-background);
}

* {
	scrollbar-color: var(--colour-scrollbar-thumb) var(--colour-scrollbar);
	scrollbar-width: thin;
}

/* Footer */

.footer {
	width: 100%;
	height: 65px;
	display: flex;
	align-items: center;
	background-color: var(--colour-nav);
	box-shadow: 0 -5px 10px var(--colour-shadow);
	position: absolute;
	flex-direction: row;
}

.footer>* {
	color: var(--colour-text);
	font-size: 16px;
	font-weight: 450;
}

.footer>.container>.footer-left {
	float: left;
}

.footer>.container>.footer-right {
	float: right;
}

.footer>.container>.footer-right>.nav {
	font-size: 16px;
}

.footer-right>a {
	margin-left: 4px;
	margin-right: 4px;
}

@media (max-width: 992px) {
	.footer {
		justify-content: center;
	}

	.footer>.container>.footer-left {
		float: none;
		text-align: center;
	}

	.footer>.container>.footer-right {
		float: none;
		text-align: center;
	}
}

/* Links */

a {
	text-decoration: none !important;
}

.link {
	color: var(--colour-link) !important;
}

.link:hover {
	color: var(--colour-link-hover) !important;
}
