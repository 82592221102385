.error-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 100px;
	color: var(--colour-text-invert);
}

.error-code {
	font-size: 40px;
}

.error-horizontal-seperator {
	font-size: 50px;
	color: var(--colour-text-invert-muted);
	font-weight: 10;
	margin-left: 5px;
	margin-right: 5px;
	margin-bottom: 5px;
	user-select: none;
}

.error-short-description {
	font-size: 40px;
}

.error-long-description {
	display: block;
	font-size: 20px;
	text-align: center;
	color: var(--colour-text-invert);
}
