.guild-container {
	display: flex;
	justify-content: center;
	margin-left: 12vw;
	margin-right: 12vw;
	margin-top: 50px;
}

.guilds {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}

.guild {
	width: 240px;
	height: 300px;
	margin-left: 20px;
	margin-right: 20px;
	margin-bottom: 40px;
	background-color: var(--colour-card);
	border-radius: 20px;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	align-items: center;

	position: relative;
	top: 0;
	left: 0;
	box-shadow: 10px 10px 5px var(--colour-shadow-invert);
	transition: all 0.1s !important;
}

.guild:hover {
	top: -8px;
	left: -8px;
	box-shadow: 18px 18px 5px var(--colour-shadow-invert);
}

.guild-icon {
	margin-top: 20px;
	margin-bottom: 20px;
	width: 200px;
	height: 200px;
	border-radius: 15px;
	overflow: hidden;
	/*background-color: var(--colour-card-title);*/
}

.guild-name {
	height: 60px;
	width: 240px;
	background-color: var(--colour-card-title);

	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	color: var(--colour-text);
	font-size: 20px;
}
