.index-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 65px);
}

.index-container>.left {
	width: 25vw;
	min-width: 200px;
	height: 100%;
	margin-right: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.index-container>.left>img {
	object-fit: contain;
	max-width: 100%;
	max-height: 100%;
}

.index-container>.right {
	width: 35vw;
	min-width: 200px;
	height: 100%;
	margin-left: 40px;
	display: flex;
	align-items: center;
	overflow: hidden;
}

@media (max-width: 992px) {
	.title {
		font-size: 80px !important;
	}

	.index-container {
		flex-direction: column;
		flex-wrap: nowrap;
	}

	.index-container>.left {
		height: 50%;
		margin-right: 0;
		align-items: flex-end;
	}

	.index-container>.right {
		height: 50%;
		margin-left: 0;
		justify-content: center;
		text-align: center;
	}

	#newline:after {
		content: "\a";
		white-space: pre;
	}
}

.title {
	font-size: 100px;
	font-weight: 300;
	margin-bottom: -5px;
	font-weight: 260;
	color: var(--colour-text-invert);
}

.subtitle {
	font-size: 22px;
	font-weight: 360;
	color: var(--colour-text-invert);
}

.index-container>.right>div {
	margin-bottom: 55px;
}

.text-highlight {
	color: orangered;
}
